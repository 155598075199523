import * as React from "react";
import "./Components/icon.css";

import AuthProvider from "./Contexts/Auth/AuthProvider";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { lazy } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Suspense } from "react";
import Loading from "./Components/Loading";
import { MadrasaTheme as theme } from "./Themes";
const Login = lazy(() => import("./pages/Auth/Login"));
const PasswordRecovery = lazy(() => import("./pages/Auth/PasswordRecovery"));
const Register = lazy(() => import("./pages/Auth/Register"));
const AuthLayout = lazy(() => import("./Layouts/Auth"));
const Home = lazy(() => import("./pages/Home"));

function App() {
    return (
        <ChakraProvider resetCSS {...{ theme }}>
            <Suspense fallback={<Loading />}>
                <AuthProvider className="App">
                    <Router>
                        <Routes>
                            <Route path="/" exact element={<Home />} />
                            <Route path="/auth" exact element={<AuthLayout />}>
                                <Route path="login" exact element={<Login />} />
                                <Route
                                    path="register"
                                    exact
                                    element={<Register />}
                                />
                                <Route
                                    path="forgot-password"
                                    exact
                                    element={<PasswordRecovery />}
                                />
                            </Route>
                        </Routes>
                    </Router>
                </AuthProvider>
            </Suspense>
        </ChakraProvider>
    );
}

export default App;

import { Box, Spinner } from '@chakra-ui/react'
import React from 'react'

const Loading = () => {
  return (
      <Box h="100vh" display="flex" justifyContent="center" alignItems="center">
          <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#3ab397"
              size="xl"
          />
      </Box>
  );
}

export default Loading;
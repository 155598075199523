export class Storage {
    storage = window.localStorage;

    static set(key, value) {
        this.storage.setItem(key, value);
    }

    static get(key) {
        return this.storage.getItem(key);
    }
}

import axios from "axios";
import { Storage } from "./storageHelper";

const baseURL = "https://madrasah-hq.herokuapp.com/api/v1";
export const GetRequest = async (endpoint, auth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        Accept: "application/json",
      };
      if (auth) {
        const token = Storage.get("token");
        headers.Authorization = `Bearer ${token}`;
      }
      await axios
        .get(`${baseURL}/${endpoint}`, { headers })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    } catch (err) {
      reject(err);
    }
  });
};

export const PostRequest = async (endpoint, body, auth = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const headers = {
        Accept: "application/json",
      };
      if (auth) {
        const token = Storage.get("token");
        headers.Authorization = `Bearer ${token}`;
      }
      await axios
        .post(`${baseURL}/${endpoint}`, JSON.stringify(body), {
          headers,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    } catch (err) {
      reject(err);
    }
  });
};

export const PutRequest = async (endpoint, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Storage.get("token");
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      await axios
        .put(`${baseURL}/${endpoint}`, JSON.stringify(body), {
          headers,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    } catch (err) {
      reject(err);
    }
  });
};

export const PatchRequest = async (endpoint, body) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Storage.get("token");
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      await axios
        .patch(`${baseURL}/${endpoint}`, JSON.stringify(body), {
          headers,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    } catch (err) {
      reject(err);
    }
  });
};

export const deleteRequest = async (endpoint) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = Storage.get("token");
      const headers = {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      await axios
        .delete(`${baseURL}/${endpoint}`, {
          headers,
        })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    } catch (err) {
      reject(err);
    }
  });
};

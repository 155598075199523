import { useState, createContext, useContext } from "react";
import { PostRequest } from "../../Utils/ApiHelpers";

const AuthContext = createContext(null);
const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const handleLogin = async (email, password) => {
    await PostRequest("auth/login/student/", { email, password }, false)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });

    // setToken(token);

    // const origin = "/dashboard";
    // window.location.href = origin;
  };

  const handleLogout = () => {
    setToken(null);
  };

  const value = {
    token,
    handleLogin,
    handleLogout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

export default AuthProvider;
